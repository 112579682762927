import { get, post } from '../utils/request';
// 蜜蜂妈妈拉新活动
const beemom = {
  /**
   * 获取当前用户信息
   * @param {object} param
   * @param {number} param.event_id // 活动ID
   */
  getCurrentUser(param) {
    const path = '/backend/bee_mom/pull_new/promoters/leader_board/current/';
    return get(path, param);
  },
  /**
   * 获取活动中用户排名列表
   * @param {object} param
   * @param {number} param.event_id // 活动ID
   * @param {number} param.page // 页码
   */
  getUserRank(param) {
    const path = '/backend/bee_mom/pull_new/promoters/leader_board/';
    return get(path, param);
  },
  /**
   * 获取活动中用户列表
   * @param {object} param
   * @param {number} param.event_id // 活动ID
   * @param {number} param.promoter_user_id // 用户ID
   * @param {bool} param.is_invitation_success // false：浏览记录 true： 邀请成功数
   * @param {number} param.page // 页码
   */
  getUserList(param) {
    const path = '/backend/bee_mom/pull_new/records/users/';
    return get(path, param);
  },
};

export default beemom;
