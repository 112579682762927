import { get, post, put, del } from './request';
import event from '../services/event';
import growth from '../services/growth';
import crm from '../services/crm';
import book from '../services/book';
import group from '../services/group';
import recruit from '../services/recruit';
import assistant from '../services/assistant';
import beemom from '../services/beemom';
import acquisition from '../services/acquisition';
import nurse from '../services/nurse';
import user from '../services/user';

const WECHAT_OAUTH_URL = 'https://open.weixin.qq.com/connect/oauth2/authorize';

interface CityObject {
  ip: string;
}

interface OrderObject {
  token: string;
}

interface WechatConfigData {
  url: string;
}

interface WechatLoginData {
  code: string;
  type: string;
  permanent?: string;
}

interface WechatGetCodeData {
  next: string;
}

interface StatisticsObject {
  token: string;
  user_id: string;
  action: string;
  hospital_id: string;
  product_id: string;
  event_id: string;
  scene_id: string;
  latitude: string;
  longitude: string;
}

export const city = {
  /**
   * getCity - ip获取城市
   *
   * @param {object} data
   * @param {string} data.ip
   * @returns
   */
  getCity(data: CityObject) {
    const path = '/backend/v1/pc/region/citys/';
    const { ip } = data;
    const headers = {};
    if (ip) {
      headers['X-User-IP'] = ip;
    }
    return get(path, {}, headers);
  },

  getCityList(data) {
    const path = '/backend/regions/lists/';
    return get(path, data || {});
  },
};

export const accounts = {
  /**
   * getUserInfo - 获取当前用户信息
   *
   * @param {object} data
   * @returns
   */
  getUserInfo() {
    const path = '/backend/user/current/';
    return get(path);
  },
  /**
   * logout - 退出登录
   *
   * @returns
   */
  logout() {
    const path = '/backend/user/logout/';
    return post(path);
  },
};

export const wechat = {
  /**
   * getConfigData - 获取 config 信息
   *
   * @param {object} data
   * @param {string} data.url
   * @returns
   */
  getConfigData(data: WechatConfigData) {
    const path = '/backend/wx/mp/config/';
    const { url } = data;
    return post(path, { url });
  },
  /**
   * login - 授权登录
   *
   * @param {object} data
   * @param {string} data.code
   * @param {string} data.permanent 1表示30天有效,0表示浏览器关闭就失效
   * @returns
   */
  login(data: WechatLoginData) {
    const { code, type, permanent } = data;
    let path = '/backend/wx/mp/login/';
    if (type === 'web') {
      path = '/backend/wx/open/login/';
    }
    return get(path, { code, permanent });
  },
  /**
   * checkAuth - 测试登录结果
   *
   * @param {object} data
   * @param {string} data.token
   * @returns
   */
  checkAuth(data) {
    const path = '/backend/auth/test/';
    const { token } = data;
    const headers = {};
    if (token) {
      headers['Authorization'] = token;
    }
    return get(path, {}, headers);
  },
  /**
   * testCookie - 测试 cookie
   *
   * @param {object} data
   * @returns
   */
  testCookie() {
    const path = '/backend/test_cookie/';
    return get(path);
  },
  /**
   * testApi - 测试 Api
   *
   * @param {object} data
   * @returns
   */
  testApi() {
    const path = '/api/demo';
    return get(path);
  },
  /**
   * getCode - 获取code
   *
   * @param {object} data
   * @param {string} data.next - callback next url
   * @returns
   */
  getCode(data: WechatGetCodeData) {
    let { next } = data;
    next = encodeURIComponent(`${next}`);
    let uri = `${process.env.WX_LOGIN_REDIRECT_URI}/wechat/auth/callback?next=${next}`;
    uri = encodeURIComponent(uri);
    const url = `${WECHAT_OAUTH_URL}?appid=${process.env.WECHAT_APP_ID}&redirect_uri=${uri}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
    window.location.href = url;
  },
};

export const home = {
  /**
   * getSku - 首页切换城市变更数据
   *
   * @param {object} data
   * @param {string} data.city_code
   * @returns
   */
  getSku(data: object) {
    const path = '/backend/v1/pc/';
    return get(path, data);
  },
  /**
   * getStatic - 首页通用数据
   *
   * @param {object} data
   * @returns
   */
  getStatic() {
    const path = '/backend/v1/pc/static/';
    return get(path);
  },
};

export const category = {
  /**
   * getCategory - 标签信息
   *
   * @param {object} data
   * @param {number} data.category_id
   * @param {string} data.type
   * @returns
   */
  getCategory(data: object) {
    const path = '/backend/v1/pc/category/pc_index_category/';
    return get(path, data);
  },
  /**
   * getSku - sku商品列表
   *
   * @param {object} data
   * @param {number} data.category_id - 品类（标签）ID
   * @param {string} data.type - 品类（标签）类型
   * @param {number} data.per_page - 每页条数
   * @param {number} data.page - 当前页数
   * @param {string} data.sort_column - 排序
   * @returns
   */
  getSku(data: object) {
    const path = '/backend/v1/pc/category/products/';
    return get(path, data);
  },
  /**
   * getShopList - 机构门店列表
   *
   * @param {object} data
   * @param {number} data.category_id - 品类（标签）ID
   * @param {string} data.type - 品类（标签）类型
   * @param {number} data.per_page - 每页条数
   * @param {number} data.page - 当前页数
   * @param {string} data.sort_column - 排序
   * @returns
   */
  getShopList(data: object) {
    const path = '/backend/v1/shop/list/';
    return get(path, data);
  },
};

export const tuan = {
  /**
   * getTuan - sku商品详情接口
   *
   * @param {object} data
   * @param {number} data.category_id - 品类（标签）ID
   * @param {string} data.category_type - 品类（标签）类型
   * @param {number} data.product_id - 商品id
   * @returns
   */
  getTuan(data: object) {
    const path = '/backend/v1/pc/product/';
    return get(path, data);
  },
  /**
   * getOrders - sku商品相关订单列表
   *
   * @param {object} data
   * @param {number} data.page - 页数
   * @param {number} data.product_id - 商品id
   * @returns
   */
  getOrders(data: object) {
    const path = '/backend/v1/pc/product/orders/';
    return get(path, data);
  },
};

export const institution = {
  /**
   * getShopDetail - 机构详情接口
   *
   * @param {object} data
   * @param {number} data.category_id - 品类（标签）ID
   * @param {string} data.category_type - 品类（标签）类型
   * @param {number} data.shop_id - 机构id
   * @returns
   */
  getShopDetail(data: object) {
    const path = '/backend/v1/shop/detail/';
    return get(path, data);
  },
  /**
   * getRecommendList - 相关机构推荐列表
   *
   * @param {object} data
   * @param {number} data.page - 页数
   * @param {number} data.shop_id - 机构id
   * @param {number} data.category_id - 品类
   * @returns
   */
  getRecommendList(data: object) {
    const path = '/backend/v1/shop/recommend/list/';
    return get(path, data);
  },
  /**
   * getProductList - 机构服务产品列表
   *
   * @param {object} data
   * @param {number} data.page - 页数
   * @param {number} data.shop_id - 机构id
   * @returns
   */
  getProductList(data: object) {
    const path = '/backend/v1/product/list/';
    return get(path, data);
  },
  /**
   * getCommentList - 医院评论列表
   *
   * @param {object} data
   * @param {number} data.page - 页数
   * @param {number} data.shop_id - 机构id
   * @returns
   */
  getCommentList(data: object) {
    const path = '/backend/v1/hospital/comment/list/';
    return get(path, data);
  },
};

export const edi = {
  /**
   * getSku - 获取edi商城sku列表数据
   *
   * @param {object} data
   * @returns
   */
  getSku(data: object) {
    const path = '/backend/v1/pc/edi/product/list/';
    return get(path, data);
  },
  /**
   * login - 登陆
   *
   * @param {object} data
   * @param {string} data.username
   * @param {string} data.password
   * @returns
   */
  login(data: object | undefined) {
    const path = '/backend/v1/pc/edi/login/';
    return post(path, data);
  },
  /**
   * register - 注册
   *
   * @param {object} data
   * @param {string} data.username
   * @param {string} data.password
   * @returns
   */
  register(data: object | undefined) {
    const path = '/backend/v1/pc/edi/register/';
    return post(path, data);
  },
  /**
   * getSkuDetail - 获取edi商品详情
   *
   * @param {object} data
   * @returns
   */
  getSkuDetail(data: object) {
    const path = '/backend/v1/pc/edi/product/detail/';
    return get(path, data);
  },
  /**
   * createOrder - 创建订单
   *
   * @param {object} data
   * @returns
   */
  createOrder(data: OrderObject | undefined) {
    const path = '/backend/v1/pc/edi/order/create/';
    const { token } = data;
    const headers = {};
    if (token) {
      headers['Authorization'] = token;
    }
    return post(path, data, headers);
  },
};

export const base = {
  /**
   * statistics - 统计
   *
   * @param {object} data
   * @param {string} data.user_id
   * @param {string} data.action
   * @param {string} data.hospital_id
   * @param {string} data.product_id
   * @param {string} data.event_id
   * @param {string} data.scene_id
   * @param {string} data.latitude
   * @param {string} data.longitude
   * @returns
   */
  statistics(data: StatisticsObject | undefined) {
    const path = '/backend/data_statistics/';
    return post(path, data);
  },
};

export {
  event,
  growth,
  crm,
  book,
  group,
  recruit,
  assistant,
  beemom,
  acquisition,
  nurse,
  user,
};
