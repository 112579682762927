// 判断是否在微信浏览器中
const isInMicroMessenger = () => {
  const ua = navigator.userAgent.toLocaleLowerCase();
  // 不可以用=== 前者返回的类型是object
  // eslint-disable-next-line
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    wx.miniProgram.getEnv(res => {
      if (res.miniprogram) {
        return false;
      }
      return true;
    });
    return true;
    // eslint-disable-next-line
  } else {
    return false;
  }
};

// 微信版本号比较
const compareVersion = (v1, v2) => {
  v1 = v1.split('.');
  v2 = v2.split('.');
  const len = Math.max(v1.length, v2.length);

  while (v1.length < len) {
    v1.push('0');
  }
  while (v2.length < len) {
    v2.push('0');
  }

  for (let i = 0; i < len; i += 1) {
    const num1 = parseInt(v1[i], 10);
    const num2 = parseInt(v2[i], 10);

    if (num1 > num2) {
      return 1;
    }
    if (num1 < num2) {
      return -1;
    }
  }
  return 0;
};

// 判断是否是小程序
const isInMiniProgram = () => {
  const ua = navigator.userAgent.toLocaleLowerCase();
  if (ua.match(/MicroMessenger/i) === 'micromessenger') {
    wx.miniProgram.getEnv(res => {
      if (res.miniprogram) {
        return true;
      }
      return false;
    });
  }
  return false;
};

/**
 * 动态加载 script
 * @param {string} src - script url
 * @param {string} id - script id
 */

const loadScript = ({ src, id }) => {
  return new Promise(resolve => {
    const existingScript = document.getElementById(id);

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      document.body.appendChild(script);

      script.onload = () => {
        resolve();
      };
      return;
    }

    if (existingScript) {
      resolve();
    }
  });
};

const loadWechatJSSDK = () => {
  return new Promise(resolve => {
    loadScript({
      src: '//res.wx.qq.com/open/js/jweixin-1.6.0.js',
      id: 'jweixin',
    }).then(resolve);
  });
};

const loadWxLogin = () => {
  return new Promise(resolve => {
    loadScript({
      src: '//res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js',
      id: 'wxLogin',
    }).then(resolve);
  });
};

const isDev = process.env.NODE_ENV !== 'production';

const isServer = typeof window === 'undefined';

const isBrowser = process.browser;

const setupWechat = async () => {
  /**
   * 注意：本地测试时，确保访问域名与 appId 绑定的 JS 安全接口域名一致！！！
   */
  // eslint-disable-next-line
  const { wechat } = require('./api.ts');
  const url = window.location.href.split('#')[0];
  const data = await wechat.getConfigData({ url });
  await loadWechatJSSDK().then(() => {
    let config = {
      debug: isDev,
      appId: '',
      timestamp: '',
      nonceStr: '',
      signature: '',
      jsApiList: [
        'chooseImage',
        'uploadImage',
        'previewImage',
        'checkJsApi',
        'translateVoice',
        'startRecord',
        'stopRecord',
        'translateVoice',
        'scanQRCode',
        'openCard',
        'getLocation',
        'onMenuShareAppMessage',
        'onMenuShareTimeline',
      ],
      openTagList: ['wx-open-launch-weapp'],
    };
    config = {
      ...config,
      appId: data.data.app_id,
      timestamp: data.data.timestamp,
      nonceStr: data.data.nonce,
      signature: data.data.signature,
    };
    wx.config(config);
  });
};

/**
 * 时间格式化
 * @param {Number} dateValue - 时间戳
 * @param {Boolean} isSecond - 是否是精确到秒，默认毫秒
 * @return {string } - 刚刚，几分钟前， 几小时前， 几天前，2020:02:02 23:99
 */
const dateFormat = (timeValue, isSecond) => {
  function zeroize(num) {
    return (String(num).length === 1 ? '0' : '') + num;
  }
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const now = new Date();
  let nowValue = now.getTime();
  if (isSecond) {
    nowValue = parseInt(nowValue / 1000, 10);
  }
  const diff = nowValue - timeValue;
  let text = '';
  if (diff < minute) {
    text = '刚刚';
  } else if (diff < hour) {
    text = `${Math.floor(diff / minute)}分钟前`;
  } else if (diff < day) {
    text = `${Math.floor(diff / hour)}小时前`;
  } else if (diff < week) {
    text = `${Math.floor(diff / day)}天前`;
  } else {
    const y = now.getFullYear();
    const m = now.getMonth() + 1;
    const d = now.getDate();
    const h = now.getHours();
    const i = now.getMinutes();
    text = `${y}.${zeroize(m)}.${zeroize(d)} ${zeroize(h)}:${zeroize(i)}`;
  }
  return text;
};

module.exports = {
  isInMicroMessenger,
  isInMiniProgram,
  loadWechatJSSDK,
  setupWechat,
  isDev,
  isServer,
  isBrowser,
  loadWxLogin,
  dateFormat,
  compareVersion,
};
