export const DEVICE_TYPE = {
  MOBILE: 'MOBILE', // 手机端
  DESKTOP: 'DESKTOP', // 桌面端
};

export const API_COOKIE_NAME = 'API_SESSIONID';
export const WEB_COOKIE_NAME = 'SESSIONID';

export const LEVEL_TYPE = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
  LEVEL_4: 4,
};

// 跳转链接类型
export const URL_TYPE = {
  H5: 1, // h5链接地址
  MINI: 2, // 小程序地址
};

// media类型
export const MEDIA_TYPE = {
  IMG: 1, // 图文贴
  VIDEO: 2, // 视频贴
  AUDIO: 3, // 音频贴
};

// 知识操作类型
export const OPERATE_TYPE = {
  LIKE: 1, // 喜欢
  UNLIKE: 2, // 不喜欢
};

// 机构评分类型
export const SCORE_TYPE = {
  MEDICAL_LEVEL: 'medical_level',
  HARDWARE_FACILITY: 'hardware_condition',
  COST_PERFORMANCE: 'cost_effective',
  MEDICAL_EFFICIENCY: 'queue_length',
  EPIDURAL: 'labor_analgesia',
  WARD_CONDITIONS: 'ward_condition',
  ESCORT: 'paternity_accompany',
  TRAFFIC_CONVENIENCE: 'traffic_jam',
};

// 机构评分类型名称
export const SCORE_NAME = {
  MEDICAL_LEVEL: '医疗水平',
  HARDWARE_FACILITY: '硬件设施',
  COST_PERFORMANCE: '性价比',
  MEDICAL_EFFICIENCY: '就医效率',
  EPIDURAL: '无痛分娩',
  WARD_CONDITIONS: '病房条件',
  ESCORT: '陪产 / 陪护',
  TRAFFIC_CONVENIENCE: '交通便捷度',
};
