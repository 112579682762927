import { get, post } from '../utils/request';

const acquisition = {
  /**
   * 活动海报二维码生成
   *
   * @param {object} data
   * @param {string} data.activiy_id - 活动id
   * @param {string} data.user_id - 用户id
   * @param {string} data.object_id - 方案id
   * @returns
   */
  generatePosterQrcode(data) {
    const { id } = data;
    const path = '/backend/poster-acquisitions/qrcode/';
    return post(path, data);
  },

  /**
   * 获取拉新活动小助理
   *
   * @param {object} data
   * @param {number} data.activity_id  活动id
   * @param {number} data.lat 纬度
   * @param {number} data.long 经度
   */
  getActivityAssistant(param) {
    const path = `/backend/acquistions/goods/${param.activity_id}/users/assistant/`;
    return get(path, param);
  },

  /**
   * 扫码记录
   *
   * @param {object} data
   * @param {number} data.inviter_id  邀请用户id
   * @param {number} data.invitee_id  被邀请用户id
   * @param {number} data.activity_id 活动id
   * @param {number} data.type  传1
   */
  postRecord(param) {
    const path = '/backend/acquisitions/record/';
    return post(path, param);
  },
};

export default acquisition;
