import { get } from '../utils/request';

export const user = {
  /**
   * 获取微信咨询顾问qrcode
   */
  getWechatInquiry(params) {
    const path = '/backend/v1/user/wehcat-inquiry/';
    return get(path, params);
  },
};

export default user;
