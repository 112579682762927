import { get, post } from '../utils/request';

const nurse = {
  /**
   * 获取月嫂面试题详情
   *
   * @param {object} param
   * @param {number} param.id - 面试题ID
   */
  getNurseInterviewList(param) {
    const { id } = param;
    const path = `/backend/yuesao/interview/question-list/${id}/`;
    console.log('path: ', path);
    return get(path);
  },

  /**
   * 获取月嫂订单列表详情
   *
   * @param {object} data
   * @param {number} data.nurse_id - 月嫂ID
   */
  getNurseOrderList(data) {
    // const { id } = param;
    const path = '/backend/v1/user/nurse/order/list';
    return get(path, data);
  },
};

export default nurse;
