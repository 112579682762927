import { get, post } from '../utils/request';

const event = {
  /**
   * getUserProfile - 获取用户数据
   *
   * @param {object} data
   * @param {string} data.ip // 用户ip
   * @param {string} data.longitude // 经度
   * @param {string} data.latitude // 纬度
   * @returns
   */
  getUserProfile(data) {
    const path = '/backend/user/profile/data/';
    return get(path, data);
  },
  /**
   * editUserProfile - 修改用户数据
   *
   * @param {object} data
   * @param {number} data.city_code 城市
   * @param {number} data.choice_type 阶段 备孕为0;自选预产期为1;算预产期为2;生日为3
   * @param {date} data.ebd 预产期 choice_type为1必传
   * @param {date} data.first_day_lmp 末次月经的起始日期 choice_type为2必传
   * @param {number} data.menstrual_cycle 周期 choice_type为2必传
   * @param {date} data.birthday 生日 choice_type为3必传
   * @returns
   */
  editUserProfile(data) {
    const { id } = data;
    const path = '/backend/user/profile/edit/';
    return post(path, data);
  },
  /**
   * addEventAddressById - 提交拉新活动收货地址
   *
   * @param {object} data
   * @param {number} data.id
   * @param {string} data.name
   * @param {string} data.phone
   * @param {string} data.address
   * @param {string} data.size
   * @returns
   */
  addEventAddressById(data) {
    const { id } = data;
    const path = `/backend/user/event/${id}/address/create/`;
    return post(path, data);
  },
  /**
   * getAddressInfoById - 查看收货地址
   *
   * @param {object} data
   * @param {number} data.id
   * @returns
   */
  getAddressInfoById(data) {
    const { id } = data;
    const path = `/backend/user/event/${id}/address/`;
    return get(path);
  },
  /**
   * getAssistantById - 查看活动小助理
   *
   * @param {object} data
   * @param {number} data.id
   * @param {string} data.channel
   * @param {string} data.src
   * @param {string} data.ip
   * @returns
   */
  getAssistantById(data) {
    const { id } = data;
    const path = `/backend/user/event/${id}/assistant/`;
    return get(path, data);
  },
  /**
   * getOfflineEventDetail - 线下拉新活动详情
   *
   * @param {object} data
   * @param {number} data.event_id
   * @returns
   */
  getOfflineEventDetail(data) {
    const path = `/backend/events/detail/`;
    return get(path, data);
  },
};

export default event;
