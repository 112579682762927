import { get, post } from '../utils/request';

const assistant = {
  /**
   * 用户小助理信息
   *
   * @param {object} data
   * @param {number} data.channel int	否	渠道号码	0:产品,1:微课 5:拉新活动 7:未知来源
   */
  getAssistant(param) {
    const path = '/backend/v1/user/assistant/';
    return get(path, param);
  },
};

export default assistant;
