import { get } from '../utils/request';

const group = {
  /**
   * getGroupList - 线索群列表
   * @param {object} data
   * @param {string} data.clue_group_ids - 多个群ID字符串
   * @param {boolean} data.show_qrcode - 是否是二维码页面
   * @returns
   */
  getGroupList(data) {
    const path = '/backend/clue/group/list/';
    return get(path, data);
  },
  /**
   * getGroupsByIds - 线索群搜索
   * @param {object} data
   * @param {string} data.group_ids - 多个群ID字符串
   * @returns
   */
  getGroupsByIds(data) {
    const path = '/backend/clue/group/search/';
    return get(path, data);
  },
};

export default group;
