import { get, post } from '../utils/request';

const book = {
  // /misc/ebook_comments/
  // 评论列表
  getCommentsList(param) {
    const path = '/backend/misc/ebook_comments/';
    return get(path, param);
  },

  // 提交评论
  postComment(data) {
    const path = '/backend/misc/ebook_comments/';
    return post(path, data);
  },

  // 点赞评论
  likeComment(data) {
    const { id } = data;
    const path = `/backend/misc/ebook_comments/${id}/favorite/`;
    return post(path);
  },
};

export default book;
