import { get } from '../utils/request';

export const recruit = {
  /**
   * 招募内容分享
   *
   * @param {object} data
   * @param {string} data.id - 内容id
   * @returns
   */
  getContentsShare(data) {
    const path = '/backend/content/share/';
    return get(path, data);
  },
};

export default recruit;
