import fetch from 'isomorphic-unfetch';
import queryString from 'query-string';
import Router from 'next/router';
import { isBrowser } from './util';
import { API_COOKIE_NAME, WEB_COOKIE_NAME } from './constant';
import { removeCookie } from './cookie';

export const data = {
  token: '',
  cookie: '',
};

const LOGIN_PAGE_PATHNAME = '/accounts/login';
enum HttpProtocol {
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  GET = 'GET',
}

function addAuthTokenToHeader(obj = {}) {
  const { token, cookie } = data;
  const headers = {
    Authorization: token,
    ...obj,
  };
  if (cookie) {
    headers['Cookie'] = cookie;
  }
  return headers;
}

function checkResponseStatus(response) {
  const { status } = response;
  if (status === 200 || status === 400 || status === 422 || status === 429) {
    return response.json();
  }
  if (status === 401) {
    // TODO: 清空登录状态
    removeCookie(API_COOKIE_NAME);
    removeCookie(WEB_COOKIE_NAME);
    data.token = '';
    const loginRedirect = '/accounts/login';
    if (isBrowser) {
      const { pathname } = window.location;
      if (pathname === LOGIN_PAGE_PATHNAME) {
        return;
      }
      Router.push(loginRedirect);
    } else {
      return 401;
    }
    return false;
  }
  if (status === 500) {
    // TODO: 处理错误
    return response.json();
  }
  if (status === 502) {
    // TODO: 处理错误
    return false;
  }
  return false;
}

async function request(
  method: HttpProtocol,
  path: string,
  params: { [key: string]: any },
  headers: { [key: string]: any },
): Promise<any> {
  let url = `${process.env.API_URL}${path}`;
  let options: any = {
    method,
    headers: {
      ...addAuthTokenToHeader(),
      ...headers,
    },
    mode: 'cors',
    credentials: 'include',
  };

  if (method === HttpProtocol.GET || method === HttpProtocol.DELETE) {
    const qs = queryString.stringify(params);
    if (qs) {
      url = `${url}?${qs}`;
    }
  } else if (method === HttpProtocol.POST || method === HttpProtocol.PUT) {
    const headers = {
      ...options.headers,
      'content-type': 'application/json',
    };
    options = {
      ...options,
      headers,
      body: JSON.stringify(params),
    };
  }
  const resp = await fetch(url, options);
  return await checkResponseStatus(resp);
}

export const get = (
  path: string,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
) => request(HttpProtocol.GET, path, params, headers);

export const post = (
  path: string,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
) => request(HttpProtocol.POST, path, params, headers);

export const put = (
  path: string,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
) => request(HttpProtocol.PUT, path, params, headers);

export const del = (
  path: string,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
) => request(HttpProtocol.DELETE, path, params, headers);
