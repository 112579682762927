import { get, post } from '../utils/request';

export const growth = {
  /**
   * 推荐成长知识
   *
   * @param {object} data
   * @param {string} data.keywords - 关键字
   * @returns
   */
  getKnowledgeList(data) {
    const path = '/backend/growth/knowledge/recommend/';
    return get(path, data);
  },
  /**
   * 喜欢成长知识
   *
   * @param {object} data
   * @param {string} data.id - 知识ID
   * @returns
   */
  likeById(data) {
    const { id } = data;
    const path = `/backend/growth/knowledge/${id}/like/`;
    return post(path);
  },
  /**
   * 不喜欢成长知识
   *
   * @param {object} data
   * @param {string} data.id - 知识ID
   * @returns
   */
  unlikeById(data) {
    const { id } = data;
    const path = `/backend/growth/knowledge/${id}/unlike/`;
    return post(path);
  },
  /**
   * 获得成长知识奖励
   *
   * @param {object} data
   * @param {string} data.id - 知识ID
   * @returns
   */
  rewardById(data) {
    const { id } = data;
    const path = `/backend/growth/knowledge/${id}/reward/	`;
    return post(path);
  },
  /**
   * 获取用户成长值信息
   *
   * @param {object} data
   * @param {string} data.id - 用户id（0 —— 代表自己）
   * @returns
   */
  getUserInfo(data) {
    const { id } = data;
    const path = `/backend/growth/info/${id}/`;
    return get(path);
  },
  /**
   * 获得奖励历史信息
   *
   * @param {object} data
   * @returns
   */
  getRewardHistory(data) {
    const path = `/backend/growth/rewarded/history/`;
    return get(path);
  },
};

export default growth;
