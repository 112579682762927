import { get, post, put, del } from '../utils/request';

const crm = {
  /**
   * getParams - 线索参数列表
   *
   * @param {object} data
   * @param {number} data.clueId
   * @returns
   */
  getParams(data) {
    const { clueId } = data;
    const path = `/backend/crm/clue/${clueId}/params/`;
    return get(path, data);
  },
  /**
   * getInfo - 线索详情
   *
   * @param {object} data
   * @param {number} data.clueId
   * @returns
   */
  getInfo(data) {
    const { clueId } = data;
    const path = `/backend/crm/clue/${clueId}/`;
    return get(path, data);
  },
  /**
   * getOrders - 线索用户订单列表
   *
   * @param {object} data
   * @param {number} data.clueId
   * @returns
   */
  getOrders(data) {
    const { clueId } = data;
    const path = `/backend/crm/clue/${clueId}/orders/`;
    return get(path, data);
  },
  /**
   * editUser - 编辑用户信息
   */
  editUser(data) {
    const path = '/backend/crm/edit_user_info/';
    return put(path, data);
  },
  /**
   * editClue - 编辑线索详情
   */
  editClue(data) {
    const clueId = data.clue_id;
    const path = `/backend/crm/clue/${clueId}/`;
    return put(path, data);
  },
  /**
   * getRecords - 线索进店记录
   *
   * @param {object} data
   * @param {number} data.clueId
   * @returns
   */
  getRecords(data) {
    const { clueId } = data;
    const path = `/backend/crm/clue/${clueId}/records/`;
    return get(path, data);
  },
  /**
   * getExperienceOrders - 体验订单列表
   *
   * @param {object} data
   * @param {number} data.clueId
   * @returns
   */
  getExperienceOrders(data) {
    const { clueId } = data;
    const path = `/backend/crm/clue/${clueId}/experience_orders/`;
    return get(path, data);
  },
  /**
   * getShops - 分店列表
   *
   * @param {object} data
   * @param {number} data.clueId
   * @param {number} data.id - 分店id
   * @param {number} data.provider_id - 商户id
   * @returns
   */
  getShops(data) {
    const { clueId } = data;
    const path = `/backend/crm/clue/${clueId}/shops/`;
    return get(path, data);
  },
  /**
   * getProviders - 商户列表
   *
   * @param {object} data
   * @param {number} data.clueId
   * @param {number} data.id - 商户id
   * @returns
   */
  getProviders(data) {
    const { clueId } = data;
    const path = `/backend/crm/clue/${clueId}/providers/`;
    return get(path, data);
  },
  /**
   * createRecords - 创建进店记录
   *
   * @param {object} data
   * @returns
   */
  createRecords(data) {
    const { clueId } = data;
    const path = `/backend/crm/clue/${clueId}/records/`;
    return post(path, data);
  },
  /**
   * editRecords - 编辑进店记录
   */
  editRecords(data) {
    const { clueId, recordId } = data;
    const path = `/backend/crm/clue/${clueId}/records/${recordId}/`;
    return put(path, data);
  },
  /**
   * deletRecords - 删除进店记录
   */
  deletRecords(data) {
    const { clueId, recordId } = data;
    const path = `/backend/crm/clue/${clueId}/records/${recordId}/`;
    return del(path, data);
  },
};

export default crm;
